// NOTE: insert new keys alphabetically in these constants
// its easier to keep track of routes then

import { appendUrlParams } from "utils/urlUtils";

import { COMMUNITY_URL_QUERY_PARAMS } from "ui/pages/ManageBrandedCommunity/ManageCommunityHome/ManageCommunityHome.data";
import {
  REFUND_URL_IDS,
  REFUND_URL_KEYS,
} from "features/Crm/modules/Transactions/modules/Refund/Refund.constants";
import {
  OFFERING_INVOICE_MANAGEMENT_PATH_NAMES,
  OFFERING_INVOICE_MANAGEMENT_ROUTE_IDS,
  OFFERING_INVOICE_MANAGEMENT_URL_KEYS,
} from "features/OfferingInvoiceManagement/OfferingInvoiceManagement.constants";
import {
  WHATSAPP_TEMPLATES_PATHNAMES,
  WHATSAPP_TEMPLATES_ROUTE_IDS,
  WHATSAPP_TEMPLATES_ROUTE_KEYS,
} from "features/WhatsappTemplate/WhatsappTemplate.constants";
import {
  AFFILIATE_MARKETING_PATHNAMES,
  AFFILIATE_MARKETING_ROUTE_IDS,
  AFFILIATE_MARKETING_URL_KEYS,
} from "features/AffiliateMarketing/constants/Affliates.urlPaths";
import {
  SALES_PAGES_ROUTE_GENERATORS,
  SALES_PAGES_ROUTE_IDS,
  SALES_PAGES_ROUTE_KEYS,
} from "features/SalesPages/constants/SalesPages.constants";
import {
  CHECKOUT_PAGES_ROUTE_GENERATORS,
  CHECKOUT_PAGE_ROUTE_IDS,
  CHECKOUT_PAGE_ROUTE_KEYS,
} from "features/CheckoutPages/constants/CheckoutPages.constants";
import {
  ONBOARDING_PATHNAMES,
  ONBOARDING_ROUTE_IDS,
  ONBOARDING_ROUTE_KEYS,
} from "features/Onboarding/constants/Onboarding.urlPaths";
import { LISTINGS_ROUTE_KEYS } from "features/Listings/constants/Listings.urlPaths";
import {
  INTEGRATION_ROUTES_GENERATORS,
  INTEGRATION_ROUTE_IDS,
  INTEGRATION_ROUTING_KEY,
} from "features/Integration/constants/Integration.urlPaths";
import {
  ACCOUNT_ROUTE_IDS,
  ACCOUNT_ROUTE_KEYS,
} from "features/Account/constants/Acoount.urlPaths";
import { whatsapp } from "features/Integration/constants/Integration.constants";
import {
  MANAGE_OFFERINGS_ROUTE_IDS,
  MANAGE_OFFERINGS_ROUTE_KEYS,
  MANAGE_OFFERINGS_ROUTE_PATHNAMES,
} from "features/ManageOfferings/constants/ManageOfferings.urlPaths";
import {
  OMNICHANNEL_ROUTE_IDS,
  OMNICHANNEL_URL_KEYS,
} from "features/OmniChannel/OmniChannel.urlPaths";
import {
  COMMUNICATIONS_PATH_NAMES,
  COMMUNICATIONS_ROUTE_KEYS,
} from "features/Communication/constants/Communications.urlPaths";
import {
  ADS_ROUTE_IDS,
  ADS_ROUTE_PATHNAMES,
} from "features/Ads/constants/Ads.urlPaths";

/**
 * for accessing other routing constants
 */
export const app_route_keys = {
  manage_webpage: "manage_webpage",
  manage_webpage_templates: "manage_webpage_templates",
  get_offers: "get_offers",
  create_link: "create_link",
  coupons: "coupons",
  past_emails: "past_emails",
  ads: "ads",
  payout_details: "payout_details",
  leads: "leads",
  app_home: "app_home",
  dashboard: "dashboard",
  whats_next: "whats_next",
  google_search_reports: "google_search_results",
  listing_reviews: "listing_reviews",
  my_subscriptions: "my_subscriptions",
  exly_connect: "exly_connect",
  cart: "cart",
  ledger: "ledger",
  subscription_transactions: "subscription_transactions",
  manage_offering: "manage_offering",
  order_bump: "order_bump",
  offering_variations: "offering_variations",
  organization: "organization",
  organization_add_member: "organization_add_member",
  organization_edit_member: "organization_edit_member",
  organization_edit_role: "organization_edit_role",
  organization_member_profile: "organization_member_profile",
  organization_roles: "organization_roles",
  search_results_page: "search_results_page",
  seo_categories: "seo_categories",
  seo_performance: "seo_performance",
  seo_performance_and_analytics: "seo_performance_and_analytics",
  zoom_attendance: "zoom_attendance",
  sales_marketing: "sales_marketing",
  zoom_attendance_of_listing: "zoom_attendance_of_listing",
  zoom_attendance_of_listing_for_customer:
    "zoom_attendance_of_listing_for_customer",
  live_webinar_attendance: " live_webinar_attendance",
  manage_communications_of_listing: "manage_communications_of_listing",
  manage_communications_email: "manage_communications_email",
  manage_communications_whatsapp_select: "whatsapp_broadcasts_select",
  manage_communications_whatsapp_publish: "whatsapp_broadcasts_publish",
  page_visitors: "page_visitors",
  traffic_sources: "traffic_sources",
  sales_revenue: "sales_revenue",
  leads_captured: "leads_captured",
  create_offering: "create_offering",
  schedule_list: "schedule_list",

  /* sales pages routes start */
  ...SALES_PAGES_ROUTE_KEYS,
  /* sales pages routes end */

  ...ADS_ROUTE_IDS,

  customer_details: "customer_details",
  whatsapp_broadcast: "whatsapp_broadcast",
  lead_details: "lead_details",
  // Integrations
  integrations: "integrations",
  integrations_google_calendar: "integrations_google_calendar",
  integrations_facebook_pixel: "integrations_facebook_pixel",
  integrations_google_analytics: "integrations_google_analytics",
  integrations_domain_linking: "integrations_domain_linking",
  integrations_wordpress: "integrations_wordpress",
  integrations_pabbly: "integrations_pabbly",
  integrations_zoom: "integrations_zoom",
  integrations_zapier: "integrations_zapier",
  shiprocket: "shiprocket",
  integrations_whatsapp: "integrations_whatsapp",
  // Sku
  webinar: "webinar",
  traffic_analysis: "traffic_analysis",
  // segments
  create_segment_conditions: "create_segment_conditions",
  edit_segment_condition: "edit_segment_condition",
  communication_sent: "communication_sent",
  view_conditions: "view_conditions",
  import_clients: "import_clients",
  course_analytics: "course_analytics",
  leaderboard: "leaderboard",
  leaderboard_breakdown_modal: "leaderboard_breakdown_modal",
  recorded_content_responses: "responses",
  whatsapp_template: "whatsapp_template",

  // Email Drip
  scheduled_emails: "scheduled_emails",
  active_drip: "active_drip",
  draft_drip: "draft_drip",
  paused_drip: "paused_drip",
  inactive_drip: "inactive_drip",
  email_drip_setup: "email_drip_setup",
  email_drip_flow: "email_drip_flow",
  email_drip_review: "email_drip_review",
  email_drip_analytics: "email_drip_analytics",
  email_drip_edit_email: "email_drip_edit_email",
  // Email Drip ends

  // Ads
  ads_online_course: "ads_online_course",

  // Marketing Creatives
  marketing_creatives: "marketing-creatives",
  marketing_creatives_edit: "marketing-creatives/edit",
  marketing_creatives_download: "marketing-creatives/download",

  // Manage Bookings
  listing_customer: "listing_customers",
  subscription_customer: "subscription_customer",
  listing_promotional_customers: "listing_promotional_customers",
  bonus_offering_customers: "bonus_offering_customers",
  booking_questions: "booking_questions",

  //Upsell
  upsell_details: "upsell_details",
  multiple_purchases_details: "multiple_purchases_details",
  attendees_details: "attendees_details",

  // Branded Community
  manage_branded_community_home: "manage_branded_community_home",
  manage_branded_community_bonus_offerings:
    "manage_branded_community_bonus_offerings",
  manage_branded_community_members: "manage_branded_community_members",
  manage_branded_community_members_purchases:
    "manage_branded_community_members_purchases",
  create_plan: "create_plan",
  compose_email: "compose_email",

  // Manage Recordings
  manage_recordings: "manage_recordings",

  manage_thankyou_upsell: "manage_thankyou_upsell",
  update_thankyou: "update_thankyou",
  idea_sourcer: "idea_sourcer",
  feature_announcements: "feature_announcements",

  existing_payments_links: "existing_payments_links",

  automated_messages: "automated_messages",

  ...OFFERING_INVOICE_MANAGEMENT_URL_KEYS,
  ...REFUND_URL_KEYS,
  ...WHATSAPP_TEMPLATES_ROUTE_KEYS,

  // Affliates
  ...AFFILIATE_MARKETING_URL_KEYS,

  ...CHECKOUT_PAGE_ROUTE_KEYS,
  ...ONBOARDING_ROUTE_KEYS,
  ...LISTINGS_ROUTE_KEYS,
  ...INTEGRATION_ROUTING_KEY,
  ...ACCOUNT_ROUTE_KEYS,
  ...OMNICHANNEL_URL_KEYS,
  ...MANAGE_OFFERINGS_ROUTE_KEYS,
  ...COMMUNICATIONS_ROUTE_KEYS,
};

export const app_route_ids = {
  [app_route_keys.app_home]: "/",
  [app_route_keys.manage_webpage]: "/TemplatePreview",
  [app_route_keys.manage_webpage_templates]: "/Templates",
  [app_route_keys.get_offers]: "/host/offer/list",
  [app_route_keys.create_link]: "/CreateLink",
  [app_route_keys.existing_payments_links]: "/payments/exly/dynamiclinks/all",
  [app_route_keys.coupons]: "/coupons",
  [app_route_keys.past_emails]: "notifications/email/list",
  [app_route_keys.ads]: "/ads",
  [app_route_keys.leads]: "host/v2/interest/list",
  [app_route_keys.payout_details]: "/payoutDetails",
  [app_route_keys.dashboard]: "/Dashboard",
  [app_route_keys.whats_next]: "/whats-next",
  [app_route_keys.schedule_list]: "ScheduleList",
  [app_route_keys.google_search_reports]: "/google-search-reports",
  [app_route_keys.my_subscriptions]: "my-subscriptions",
  [app_route_keys.ledger]: "ledger/:feature_key",
  [app_route_keys.exly_connect]: "/exly-connect/:tab?",
  [app_route_keys.cart]: "cart",
  [app_route_keys.order_bump]: "order-bump",
  [app_route_keys.offering_variations]: "offering-variations/:offeringUuid",
  [app_route_keys.subscription_transactions]: "subscription-transactions",
  [app_route_keys.listing_reviews]: "host/listing/reviews",
  [app_route_keys.manage_offering]: "manage-offering",
  [app_route_keys.organization]: "users/get/org/members",
  [app_route_keys.organization_add_member]: "/organization/member/add",
  [app_route_keys.organization_edit_member]:
    "/organization/member/edit/:memberId",
  [app_route_keys.organization_edit_role]:
    "/organization/role-permission-edit/:role",
  [app_route_keys.organization_member_profile]:
    "/organization/member/profile/:memberId",
  [app_route_keys.organization_roles]: "/organization/roles",
  [app_route_keys.seo_categories]: "/seo-categories",
  [app_route_keys.search_results_page]: "/search-results-page",
  [app_route_keys.seo_performance_and_analytics]:
    "/seo-performance-and-analytics",
  [app_route_keys.seo_performance]: "/seo-performance",
  [app_route_keys.zoom_attendance]: "zoom-attendance",
  [app_route_keys.zoom_attendance_of_listing]:
    "zoom-attendance-listing/:listing_uuid",
  [app_route_keys.live_webinar_attendance]:
    "zoom-attendance-listing/:listing_uuid/slot/:slotUuid",
  [app_route_keys.zoom_attendance_of_listing_for_customer]:
    "zoom-attendance-of-listing-for-customer",
  [app_route_keys.manage_communications_of_listing]:
    "/ScheduleList/ManageCommunications/:listing_uuid",
  [app_route_keys.manage_communications_email]:
    "/ScheduleList/EmailCommunication",
  [app_route_keys.manage_communications_whatsapp_select]:
    "/whatsapp-broadcasts/select",
  [app_route_keys.manage_communications_whatsapp_publish]:
    "/whatsapp-broadcasts/publish",
  [app_route_keys.page_visitors]: "/page-visitors",
  [app_route_keys.sales_revenue]: "/sales-revenue",
  [app_route_keys.sales_marketing]: "/sales-and-marketing",

  [app_route_keys.leads_captured]: "/leads-captured",
  [app_route_keys.traffic_sources]: "/traffic-sources",
  [app_route_keys.whatsapp_broadcast]: "/whatsapp-broadcasts",
  [app_route_keys.create_offering]: "CreateScheduleType",

  /* sales pages routes start */
  ...SALES_PAGES_ROUTE_IDS,
  /* sales pages routes end */

  ...ADS_ROUTE_IDS,

  [app_route_keys.traffic_analysis]: "host/affliate/paginated/list",

  [app_route_keys.customer_details]: "/customer-details",
  [app_route_keys.lead_details]: "/lead-details",
  // Integrations
  [app_route_keys.integrations]: "/Integrations",
  [app_route_keys.integrations_google_calendar]:
    "/Integrations/GoogleCalendar/:is_synced",
  [app_route_keys.integrations_facebook_pixel]:
    "/Integrations/FacebookPixel/:is_synced",
  [app_route_keys.integrations_google_analytics]:
    "/Integrations/GoogleAnalytics/:is_synced",
  [app_route_keys.integrations_domain_linking]:
    "/Integrations/DomainLinking/:is_synced",
  [app_route_keys.integrations_wordpress]: "/Integrations/WordPress/:is_synced",
  [app_route_keys.integrations_pabbly]: "/Integrations/Pabbly/:is_synced",
  [app_route_keys.integrations_zoom]: "/Integrations/Zoom/:is_synced",
  [app_route_keys.integrations_zapier]: "/Integrations/zapier",
  [app_route_keys.shiprocket]: "/Integrations/shiprocket",
  [app_route_keys.integrations_whatsapp]: `/Integrations/${whatsapp}`,
  // Sku's
  [app_route_keys.webinar]: "/webinar/:id",
  // segments
  [app_route_keys.create_segment_conditions]: "/segments/create/conditions",
  [app_route_keys.edit_segment_condition]:
    "/segments/create/conditions/:segment_id",
  [app_route_keys.communication_sent]: "/segments/comm-sent/:segment_id",
  [app_route_keys.view_conditions]: "segments/conditions/:segment_id",

  [app_route_keys.import_clients]: "ImportClients/:type",
  [app_route_keys.recorded_content_responses]:
    "responses/:customer_name/:type/:listing_uuid/:booking_uuid",
  [app_route_keys.course_analytics]: "course_analytics/:listing_uuid",
  [app_route_keys.leaderboard]: "leaderboard/:listing_uuid",
  [app_route_keys.leaderboard_breakdown_modal]: "leaderboard_breakdown_modal",
  [app_route_keys.whatsapp_template]: "/WhatsappTemplate",

  // Email Drip
  [app_route_keys.scheduled_emails]: "scheduled-emails",
  [app_route_keys.active_drip]: "drip/active",
  [app_route_keys.draft_drip]: "drip/draft",
  [app_route_keys.paused_drip]: "drip/paused",
  [app_route_keys.inactive_drip]: "drip/inactive",
  [app_route_keys.email_drip_analytics]: "email-drip/analytics/:dripUuid",
  [app_route_keys.email_drip_edit_email]: "/email-drip/:dripUuid/edit/:emailId",
  [app_route_keys.email_drip_setup]: "/email-drip/:dripUuid/setup",
  [app_route_keys.email_drip_flow]: "/email-drip/:dripUuid/drip-flow",
  [app_route_keys.email_drip_review]: "/email-drip/:dripUuid/review",
  // Email drip ends

  //ads
  [app_route_keys.ads_online_course]: "/ads/online-course/:id",

  // Marketing Creatives
  [app_route_keys.marketing_creatives]: "/marketing-creatives",
  [app_route_keys.marketing_creatives_edit]: "/marketing-creatives/edit",
  [app_route_keys.marketing_creatives_download]:
    "/marketing-creatives/download",

  // Manage Bookings
  [app_route_keys.listing_customer]: "listingCustomers",
  [app_route_keys.subscription_customer]: "subscriptionCustomers",
  [app_route_keys.bonus_offering_customers]: "bonusOfferingCustomers",
  // @Dev, the listing_uuid has been moved to matchParams instead of queryParams. This adjustment was made because queryParams are eliminated upon navigation using ExlyTable. ExlyTable appends its own parameters for functions like pagination and filtering.
  [app_route_keys.booking_questions]: "booking-questions/:listing_uuid",

  [app_route_keys.upsell_details]: `listingCustomers/upsells`,
  [app_route_keys.multiple_purchases_details]: `listingCustomers/multiplePurchases`,
  [app_route_keys.attendees_details]: `listingCustomers/attendees`,

  // Branded Community
  [app_route_keys.manage_branded_community_home]:
    "manage-branded-community/:branded_community_uuid/home",
  [app_route_keys.manage_branded_community_bonus_offerings]:
    "manage-branded-community/:branded_community_uuid/bonus-offerings",
  [app_route_keys.manage_branded_community_members]:
    "manage-branded-community/:branded_community_uuid/members",
  [app_route_keys.manage_branded_community_members_purchases]:
    "manage-branded-community/:branded_community_uuid/member/:member_uuid/purchases",

  // plan
  [app_route_keys.create_plan]: "/plan/:id",
  [app_route_keys.compose_email]: "ComposeEmail",

  // Manage Recordings
  [app_route_keys.manage_recordings]: "manage_recordings/:listing_uuid",

  [app_route_keys.manage_thankyou_upsell]: "/manage-thankyou-and-upsell",
  [app_route_keys.update_thankyou]: "/update-thankyou/:type",
  [app_route_keys.idea_sourcer]: "/ideas",
  [app_route_keys.feature_announcements]: "/announcements",

  [app_route_keys.automated_messages]: "/notifications/automated-messages",

  ...AFFILIATE_MARKETING_ROUTE_IDS,
  ...REFUND_URL_IDS,

  ...OFFERING_INVOICE_MANAGEMENT_ROUTE_IDS,
  ...WHATSAPP_TEMPLATES_ROUTE_IDS,
  ...CHECKOUT_PAGE_ROUTE_IDS,
  ...ONBOARDING_ROUTE_IDS,
  ...INTEGRATION_ROUTE_IDS,
  ...ACCOUNT_ROUTE_IDS,
  ...OMNICHANNEL_ROUTE_IDS,
  ...MANAGE_OFFERINGS_ROUTE_IDS,
};

export const app_pathnames = {
  [app_route_keys.my_subscriptions]: () => "/my-subscriptions",
  [app_route_keys.exly_connect]: (selectedTab) =>
    selectedTab ? `exly-connect/${selectedTab}` : `exly-connect`,
  [app_route_keys.ledger]: (feature_key) => `/ledger/${feature_key}`,
  [app_route_keys.import_clients]: (type) => `ImportClients/${type}`,
  [app_route_keys.cart]: () => "/cart",
  [app_route_keys.order_bump]: (listingUuid) => `order-bump/${listingUuid}`,
  [app_route_keys.offering_variations]: ({ offeringUuid }) =>
    `offering-variations/${offeringUuid}`,
  [app_route_keys.subscription_transactions]: () =>
    "/subscription-transactions",
  [app_route_keys.zoom_attendance]: () => "/zoom-attendance",
  [app_route_keys.zoom_attendance_of_listing]: ({ listingUuid }) =>
    "/zoom-attendance-listing/" + listingUuid,
  [app_route_keys.live_webinar_attendance]: ({ listingUuid, slotUuid }) =>
    "/zoom-attendance-listing/" + listingUuid + "/slot/" + slotUuid,
  // create is added to show Create/Edit in heading
  [app_route_keys.email_drip_setup]: (dripUuid, create) =>
    create
      ? `/email-drip/${dripUuid}/setup?create=${create}`
      : `/email-drip/${dripUuid}/setup`,
  [app_route_keys.email_drip_flow]: (dripUuid, create) =>
    create
      ? `/email-drip/${dripUuid}/drip-flow?create=${create}`
      : `/email-drip/${dripUuid}/drip-flow`,
  [app_route_keys.email_drip_review]: (dripUuid, create) =>
    create
      ? `/email-drip/${dripUuid}/review?create=${create}`
      : `/email-drip/${dripUuid}/review`,
  [app_route_keys.email_drip_analytics]: (dripUuid) =>
    `/email-drip/analytics/${dripUuid}`,
  [app_route_keys.email_drip_edit_email]: (dripUuid, emailId) =>
    `/email-drip/${dripUuid}/edit/${emailId}`,
  [app_route_keys.manage_communications_of_listing]: ({ listing_uuid }) =>
    `/ScheduleList/ManageCommunications/${listing_uuid}`,

  /* sales pages routes start */
  ...SALES_PAGES_ROUTE_GENERATORS,
  /* sales pages routes end */

  ...ADS_ROUTE_PATHNAMES,

  /* integrations routes start */
  ...INTEGRATION_ROUTES_GENERATORS,
  /* integrations routes end */

  // Branded Community
  [app_route_keys.manage_branded_community_home]: ({
    brandedCommunityUuid,
    section,
  }) =>
    appendUrlParams(`manage-branded-community/${brandedCommunityUuid}/home`, {
      [COMMUNITY_URL_QUERY_PARAMS.selected_section]: section,
    }),
  [app_route_keys.manage_branded_community_bonus_offerings]: ({
    brandedCommunityUuid,
  }) => `manage-branded-community/${brandedCommunityUuid}/bonus-offerings`,
  [app_route_keys.manage_branded_community_members]: ({
    brandedCommunityUuid,
  }) => `manage-branded-community/${brandedCommunityUuid}/members`,

  // custom segment
  [app_route_keys.view_conditions]: ({ segment_id, segmentName, type }) =>
    appendUrlParams(`/segments/conditions/${segment_id}`, {
      segmentName,
      type,
    }),

  [app_route_keys.communication_sent]: ({ segment_id, segmentName, type }) =>
    appendUrlParams(`/segments/comm-sent/${segment_id}`, {
      segmentName,
      type,
    }),

  // plan
  [app_route_keys.create_plan]: ({ planId, page }) =>
    appendUrlParams(`/plan/${planId}`, {
      page,
    }),
  // Thank you page update
  [app_route_keys.update_thankyou]: ({ type, listing_uuid }) =>
    `/update-thankyou/${type}?listing_uuid=${listing_uuid}`,

  // Manage Recordings
  [app_route_keys.manage_recordings]: (listing_uuid) =>
    `manage_recordings/${listing_uuid}`,

  [app_route_keys.booking_questions]: (listing_uuid) =>
    `booking-questions/${listing_uuid}`,

  ...OFFERING_INVOICE_MANAGEMENT_PATH_NAMES,
  ...WHATSAPP_TEMPLATES_PATHNAMES,
  ...AFFILIATE_MARKETING_PATHNAMES,
  ...CHECKOUT_PAGES_ROUTE_GENERATORS,
  ...ONBOARDING_PATHNAMES,
  ...MANAGE_OFFERINGS_ROUTE_PATHNAMES,
  ...COMMUNICATIONS_PATH_NAMES,
};

export const app_route_titles = {
  [app_route_keys.zoom_attendance]: "Attendance",
  [app_route_keys.integrations]: "Integrations",
  [app_route_keys.sales_pages_home]: "Landing pages",
};
